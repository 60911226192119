import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Body } from '../../styles';

export const BodyContainer = styled(Body)`
  padding: 40px 110px;
  @media (max-width: 767px) {
    padding: 35px;
  }
`;

export const BodyStyle = styled.div`
  padding: 40px 110px 0 110px;

  @media (max-width: 1200px) {
    padding: 50px 50px 0 50px;
  }
  @media (max-width: 767px) {
    padding: 35px 35px 0 35px;
  }
`;
export const CallOut = styled.div`
  width: 100%;
  padding-top: 30px;
  padding-left: 60px;
  background: rgb(0, 166, 232);
  background: -moz-linear-gradient(
    top,
    rgba(0, 166, 232, 1) 0%,
    rgba(0, 83, 157, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 166, 232, 1) 0%,
    rgba(0, 83, 157, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 166, 232, 1) 0%,
    rgba(0, 83, 157, 1) 100%
  );
  border-radius: 20px;
  @media (max-width: 1199px) {
    padding: 50px 30px 0 30px;
    text-align: center;
  }
  ::after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
  a {
    color: white;
    margin: 0px;
  }
  p {
    margin: 0px;
  }
  img {
    margin: 0px;
  }

  @media (max-width: 991px) {
    a {
      color: white;
      margin-bottom: 30px;
    }
    p {
      margin-bottom: 30px;
    }
    img {
      margin-bottom: 30px;
    }
  } ;
`;

export const LeftSection = styled.div`
  float: left;
  width: 55%;
  height: 170px;
  padding-top: 30px;
  padding-left: 60px;
  font-family: 'MrAlex', arial, sans-serif;
  font-size: 5vw;
  line-height: 5vw;
  color: #fff;
  @media (max-width: 1830px) {
    width: 47%;
    height: 150px;
    font-size: 4vw;
    line-height: 4vw;
  }
  @media (max-width: 1300px) {
    float: none;
    width: 100%;
    height: auto;
    padding-top: 0;
    padding-left: 0;
    margin-bottom: 30px;
    font-size: 80px;
    line-height: 80px;
  }
  @media (max-width: 767px) {
    font-size: 50px;
    line-height: 50px;
  }
`;

export const MiddleSection = styled.div`
  position: relative;
  float: left;
  width: 22%;
  height: 170px;
  @media (max-width: 1830px) {
    width: 22%;
    height: 150px;
  }
  @media (max-width: 1300px) {
    float: none;
    width: 100%;
    height: auto;
  }
`;

export const RightSection = styled.div`
  float: right;
  width: 23%;
  height: 170px;
  padding-top: 45px;
  text-align: center;
  @media (max-width: 1830px) {
    width: 28%;
    height: 150px;
    padding-top: 30px;
  }
  @media (max-width: 1439px) {
    width: 22%;
  }
  @media (max-width: 1300px) {
    float: none;
    width: 100%;
    height: auto;
    padding-top: 0;
    margin-bottom: 30px;
  }
`;

export const ImageStyle = styled.img`
  border: 0;
  vertical-align: top;
  position: relative;
  bottom: 0;
  width: auto;
  height: 170px;
  @media (max-width: 1830px) {
    height: 150px;
  }
  @media (max-width: 1300px) {
    height: 150px;
  }
  @media (max-width: 767px) {
    width: 80%;
    height: auto;
  }
`;

export const ButtonStyle = styled.a`
  display: inline-block;
  position: relative;
  top: 0;
  padding: 15px 35px;
  margin-right: 10px;
  font-family: 'MrAlex', sans-serif;
  font-size: 24px;
  line-height: 28px;
  color: #00a6e8;
  border: 2px solid #00a6e8;
  box-shadow: 0 0 0 2px rgb(255 255 255 / 0%);
  border-radius: 100px;
  text-decoration: none;

  transition: 0.25s ease-out;
  cursor: pointer;
  @media (max-width: 1439px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 1300px) {
    font-size: 25px;
    line-height: 25px;
  }
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 25px;
  }
`;

export const ColStyle = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BlueMainContainer = styled.div`
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(237, 237, 237, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(237, 237, 237, 1) 100%
  );
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  font-size: 20px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.6);
  @media (max-width: 1399px) {
    font-size: 20px;
    line-height: 32px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
